import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import { getData } from "../../helper/helper";
import Layout from "../../components/Layout";

const BlogPage = ({ pageContext: { locale }, ...props }) => {
  const { edges: posts } = props.data.blogPosts;
  const blog = getData(locale).blogPage;
  return (
    <Layout locale={locale} title={blog.title} description={blog.description}>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={`${blog.description}`} />
      </Helmet>
      <section className="subPage">
        <div className="image">
          <div className="post-image">
            <img src={blog.image} alt={blog.title} title={blog.title} />
          </div>

          <div className="titles">
            <h1>{blog.title}</h1>
            <p>{blog.description}</p>
          </div>
        </div>
        <div className="container blogs">
          <div className="columns">
            {posts
              .map(({ node: post }) => (
                <Link
                  to={`${post.fields.slug}`}
                  title={post.frontmatter.title}
                  key={post.fields.slug}
                  className="column"
                >
                  <Img
                    fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                    alt={post.frontmatter.title}
                    title={post.frontmatter.title}
                    className="image"
                  />
                  <div className="cover">
                    <h1>{post.frontmatter.title}</h1>
                    <span />
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPage;

BlogPage.propTypes = {
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query blogPageQuery($locale: String) {
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          pageKey: { eq: "page_blogpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            pageKey
            description
            locale
            category
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
